import { Link, navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { isLoggedIn } from "../utils/auth";

export default function RegisterPage() {
  /* useEffect(() => {
    if (isLoggedIn()) {
      debugger;
      navigate(`/app/dashboard`);
      return null;
    }
  }, []);

  if (isLoggedIn()) {
    debugger;
    navigate(`/app/dashboard`);
    return null;
  } */

  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-12 col-lg-12 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fw-800 mb-4">Register account</h1>
              <p className="fw-airlight mt-4">Are you a...</p>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 pb-5 pt-4 mx-auto text-center">
              <div className="row justify-content-center ">
                <div className="col-md-3 wb-center">
                  <div className="img-cercle nav-link mx-5 disabled bg-light">
                    <Link to="/user-registration disabled">
                      <img src="../img/video-call.svg" alt="customer" />
                    </Link>
                  </div>
                  <p className="text-dark fw-airlight fw-bold mt-2">Customer</p>
                </div>
                <div className="col-md-3 wb-center">
                  <div className="img-cercle nav-link mx-5">
                    <Link to="/merchant-user">
                      <img src="../img/shoe-shop.svg" alt="merchant" />
                    </Link>
                  </div>
                  <p className="text-dark fw-airlight fw-bold mt-2">Merchant</p>
                </div>
                <div className="col-md-3 wb-center">
                  <div className="img-cercle nav-link mx-5 disabled bg-light">
                    <Link to="/influencer-register disabled">
                      <img
                        src="../img/influencer-register.svg"
                        alt="influencer"
                      />
                    </Link>
                  </div>
                  <p className="text-dark fw-airlight fw-bold mt-2">
                    Influencer
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5">
          <section className="Light-bg my-5">
            <Row>
              <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-12 ps-0 bg-last-section">
                <img src="../img/Onboarding.svg" alt="bg" />
              </div>
              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 ps-lg-0 pt-xl-5 pt-lg-5 pb-sm-5 ps-sm-5 pt-sm-5 ps-xxl-0 ps-xl-5">
                <h4 className="fs-40 fw-bold mb-5">Are you a merchant?</h4>
                <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-xl-5 pe-lg-5 pe-md-5 pe-0 me-xl-5 me-lg-5 me-md-5 me-0">
                  Increase foot traffic and sustainably grow your profitability
                  with The BOGO App’s Dynamic Campaign Management™ system
                </h5>
                <Link to="#" className="fs-18 link-color pb-2">
                  BOGO for Business{" "}
                  <img
                    src="../img/arrow-right.svg"
                    alt="arrow right"
                    className="ms-3"
                  />
                </Link>
              </div>
            </Row>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
